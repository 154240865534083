<template>
  <Field v-model="model"
         v-bind="{ ...$props, ...$attrs }"
         v-on="$listeners"
         border
         :error="false"
         :style="fieldStyle"
         :placeholder="$attrs.placeholder || (readonly ? '' : '请输入')">
    <template v-for="(v, slot) in $slots"
              :slot="slot">
      <slot :name="slot" />
    </template>
  </Field>
</template>
<script>
import { Field } from 'vant'
export default {
  name: 'field',
  components: {
    Field
  },
  props: {
    value: {
      type: [Number, String],
      default: ''
    }
  },
  data () {
    return {
      model: ''
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.model = value
      }
    },
    model (value) {
      this.$emit('input', value)
    }
  },
  computed: {
    fieldStyle () {
      const style = {}
      if (this.$attrs.direction === 'column') {
        style['display'] = 'block'
      }
      return style
    },
    readonly () {
      return this.$attrs.readonly === '' || this.$attrs.readonly
    }
  }
}
</script>
<style lang="less" scoped>
@import "./field.less";
</style>
<style lang="less">
textarea {
  line-height: 1.5 !important;
}
</style>